var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.pending}},[_c('v-card-text',{staticClass:"pa-5 black--text text-body-1"},[(_vm.pending && !_vm.talentUser)?_c('div',[_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"heading"}}),_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"paragraph, paragraph"}})],1):_vm._e(),_c('contact-info',{staticClass:"mb-3",attrs:{"talent-user":_vm.talentUser}}),_c('contact-section',{staticClass:"mb-4",attrs:{"total":_vm.tags.length,"pending":false,"icon":"mdi-tag","title":"Теги","pages":1,"current-page":1},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-divider'),_c('v-card-text',{staticClass:"black--text text-body-1 pa-4"},[_c('tag-search',{attrs:{"selected-tags":_vm.tags},on:{"tagClick":_vm.handleTagClick}})],1)]},proxy:true}])},[_vm._l((_vm.tags),function(item){return _c('v-chip',{key:item.id,staticClass:"mr-2 mb-2",attrs:{"color":item.color,"dark":"","small":"","title":`Показать всех пользователей с тегом «${item.name}»`},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
            name: 'search',
            query: {
              tags_ids: item.id,
              search: '',
            },
          })}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{attrs:{"right":"","size":"18px","title":"Удалить тег"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveTag(item)}}},[_vm._v("mdi-close")])],1)}),(!_vm.tags.length)?_c('div',[_vm._v("Нет тегов")]):_vm._e()],2),_c('contact-section',{staticClass:"mb-4",attrs:{"total":_vm.comments.total,"pending":_vm.comments.pending,"icon":"mdi-message","title":"Комментарии","pages":_vm.comments.pagesCount,"current-page":_vm.comments.page},on:{"setPage":_vm.getComments},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-divider'),_c('v-card-text',{staticClass:"black--text text-body-1 pa-4"},[_c('comment-form',{attrs:{"user-id":_vm.talentId},on:{"success":function($event){return _vm.getComments(1)}}})],1)]},proxy:true}])},[_vm._l((_vm.comments.list),function(item){return _c('comment-card',{key:item.id,staticClass:"contact-item",attrs:{"comment":item},on:{"removeComment":_vm.handleRemoveComment}})}),(!_vm.comments.total && !_vm.comments.pending)?_c('div',[_vm._v("Нет комментариев")]):_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Закрыть")]),_c('v-btn',{attrs:{"color":"primary","outlined":"","to":{ name: 'contact', params: { id: _vm.talentId } }}},[_vm._v("Перейти к карточке")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }