<template>
  <v-card class="mb-4" :loading="pending">
    <v-toolbar color="blue-grey darken-2" dark dense flat>
      <v-toolbar-title class="white--text"
        ><v-icon v-if="icon" left>{{ icon }}</v-icon
        >{{ title }}
      </v-toolbar-title>
      <v-chip
        v-if="typeof total === 'number'"
        color="blue-grey lighten-4"
        light
        small
        class="ml-2"
        >{{ total }}</v-chip
      >
    </v-toolbar>

    <v-card-text class="black--text text-body-1 pa-4">
      <slot></slot>
    </v-card-text>

    <v-card-actions v-show="pages > 1" class="pb-4 pt-0">
      <v-pagination
        v-model="page"
        class="justify-start"
        :total-visible="7"
        :length="pages"
        :disabled="pending"
      ></v-pagination>
    </v-card-actions>

    <slot name="footer"></slot>
  </v-card>
</template>

<script>
export default {
  name: "ContactSection",
  props: {
    total: Number,
    pending: Boolean,
    icon: String,
    title: String,
    pages: Number,
    currentPage: Number,
  },
  computed: {
    page: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("setPage", val);
      },
    },
  },
};
</script>

<style></style>
