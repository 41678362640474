<template>
  <div v-if="talentUser">
    <div>
      <v-avatar
        v-if="talentUser.avatar"
        color="#dedede"
        class="mb-2"
        darken
        size="80"
        elevation="1"
      >
        <img class="object-fit-cover" :src="talentUser.avatar" />
      </v-avatar>

      <div class="text-h6 flex-grow-1">{{ fullName }}</div>
    </div>
    <option-row><span slot="title">ID:</span>{{ talentUser.id }}</option-row>
    <template v-if="bDay">
      <option-row
        ><span slot="title">Дата рождения:</span>{{ bDay }}</option-row
      >
      <option-row v-if="talentUser.birthday"
        ><span slot="title">Возраст:</span>{{ age }}</option-row
      >
    </template>
    <option-row v-if="formattedSnils" title="СНИЛС">{{
      formattedSnils
    }}</option-row>
    <option-row v-if="address"
      ><span slot="title">Адрес:</span>{{ address }}</option-row
    >
    <option-row v-if="talentUser.phone"
      ><span slot="title">Телефон:</span>{{ talentUser.phone }}
      <span
        v-if="typeof talentUser.phone_confirmed === 'boolean'"
        :class="talentUser.phone_confirmed ? 'primary--text' : 'error--text'"
        >({{
          talentUser.phone_confirmed ? "Подтвержден" : "Не подтвержден"
        }})</span
      ></option-row
    >
    <option-row
      ><span slot="title">Эл.почта:</span
      ><a :href="`mailto:${talentUser.email}`">{{
        talentUser.email
      }}</a></option-row
    >
    <option-row v-if="age && age < 18"
      ><span slot="title">Статус файла согласия:</span
      ><span :class="confirmStatusClasses">{{
        confirmStatus
      }}</span></option-row
    >
    <option-row v-if="talentUser.sex"
      ><span slot="title">Пол:</span
      >{{ talentUser.sex === "m" ? "Мужской" : "Женский" }}</option-row
    >
    <option-row v-if="dateJoined"
      ><span slot="title">На платформе с:</span>{{ dateJoined }}</option-row
    >
    <option-row v-if="lastLogin"
      ><span slot="title">Последняя авторизация:</span
      >{{ lastLogin }}</option-row
    >
  </div>
</template>

<script>
import { addressJoin } from "@/api/utils/dataParsers";
import dayjs from "@/plugins/dayjs";
import {
  ADULT_CONFIRM_STATUS_ACCEPTED,
  ADULT_CONFIRM_STATUS_MODERATION,
  ADULT_CONFIRM_STATUS_NONE,
  DATE_DATABASE_FORMAT,
  DATE_DISPLAY_FORMAT,
} from "@/constants";
export default {
  name: "ContactInfo",
  props: {
    talentUser: {
      type: Object,
    },
  },
  computed: {
    fullName() {
      const { talentUser } = this;
      if (!talentUser) return;
      return [
        talentUser.last_name,
        talentUser.first_name,
        talentUser.middle_name,
      ]
        .filter(Boolean)
        .join(" ");
    },
    bDay() {
      const { talentUser } = this;
      if (!talentUser || !talentUser.birthday) return null;

      return dayjs(talentUser.birthday, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    dateJoined() {
      const { talentUser } = this;
      if (!talentUser?.date_joined) return null;
      const formattedDate = dayjs(talentUser.date_joined, DATE_DATABASE_FORMAT);
      const date = formattedDate.format(DATE_DISPLAY_FORMAT);
      const i = formattedDate.toNow(true);
      return `${date} (${i})`;
    },
    address() {
      const { talentUser } = this;
      if (!talentUser) return;
      return addressJoin(talentUser);
    },
    formattedSnils() {
      const { talentUser } = this;
      if (!talentUser?.snils) return;
      return talentUser.snils.match(/.{1,3}/g).join("-");
    },
    age() {
      const bday = this.talentUser?.birthday;
      if (!bday) return;
      return dayjs().diff(bday, "year");
    },
    lastLogin() {
      const date = this.talentUser?.last_login;
      if (!date) return;
      return dayjs(this.talentUser.last_login).fromNow();
    },
    confirmStatus() {
      const status = this.talentUser?.adult_confirmed;
      switch (status) {
        case ADULT_CONFIRM_STATUS_ACCEPTED:
          return "Подтвержден";
        case ADULT_CONFIRM_STATUS_MODERATION:
          return "На модерации";
        default:
          return "Не загружен";
      }
    },
    confirmStatusClasses() {
      const status = this.talentUser?.adult_confirmed;
      return {
        "info--text": status === ADULT_CONFIRM_STATUS_MODERATION,
        "error--text": status === ADULT_CONFIRM_STATUS_NONE,
      };
    },
  },
};
</script>
